import { iPlanCategory } from "./interfaces/plans";

const Plans: iPlanCategory[] = [
  {
    title: "Pessoa física",
    plans: [
      {
        title: "Essencial",
        plans: [
          {
            title: "Essencial Fit",
            speed: 300,
            speedType: "MB",
            amount: 89.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/b97b8e80-f106-4a01-86af-a2f818538504",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Essencial Plus",
            speed: 600,
            speedType: "MB",
            amount: 99.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/2f2d527b-dd02-441a-994b-d375fc71c2b0",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Essencial Connect",
            speed: 700,
            speedType: "MB",
            amount: 109.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/ba52fc4d-94bd-4c5b-9cd1-f3c9571cdc24",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
            ],
          },
          {
            title: "Essencial Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/0ed6f17e-df5b-4dde-93d1-12952c5099a0",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Essencial Fit",
        plans: [
          {
            title: "Fit",
            speed: 500,
            speedType: "MB",
            amount: 89.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/cfcf783d-9d99-4508-a90a-ca060bf5ecf2",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Fit + Móvel",
            speed: 500,
            speedType: "MB",
            amount: 124.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/40b62a6a-ff52-44e2-b360-c9fb600c6de8",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "15 GB para pacote mobile",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Fit + Max",
            speed: 500,
            speedType: "MB",
            amount: 199.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/7769125e-8f93-42dc-b04a-54e4b9481833",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Essencial Smart",
        plans: [
          {
            title: "Smart",
            speed: 700,
            speedType: "MB",
            amount: 99.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/68e87faf-4478-48eb-949a-0d18b8227b6b",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Smart + Móvel",
            speed: 700,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/7a5d0b58-fdee-4857-b1a6-95bf0ae2ba29",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "30 GB para pacote mobile",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Smart + Globoplay",
            speed: 700,
            speedType: "MB",
            amount: 124.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/da622255-40dc-4274-9fbd-bd1f1af41c58",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "Globoplay | c/ anúncio",
                icon: "globoplay.png",
              },
            ],
          },
          {
            title: "Smart + Wifi",
            speed: 700,
            speedType: "MB",
            amount: 109.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/97b0c968-65ee-476c-a39e-76edcc91c053",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "ITOP FI",
                icon: "router.png",
              },
            ],
          },
          {
            title: "Smart + Max",
            speed: 700,
            speedType: "MB",
            amount: 124.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/d37b1652-ac17-49aa-b695-9ef4ffcca2bc",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Essencial Plus",
        plans: [
          {
            title: "Plus",
            speed: 800,
            speedType: "MB",
            amount: 114.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/abad795d-3c2c-4917-be2e-4b6810857ff9",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Plus + Móvel",
            speed: 800,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/4bb95314-04e5-4cd4-9374-641b5e2911e0",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "40 GB para pacote mobile",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Plus + Globoplay",
            speed: 800,
            speedType: "MB",
            amount: 134.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/ce154fc4-3faf-4527-beb7-f4e58e2788d8",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "Globoplay | c/ anúncio",
                icon: "globoplay.png",
              },
            ],
          },
          {
            title: "Plus + Wifi",
            speed: 800,
            speedType: "MB",
            amount: 119.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/25523077-f127-46ba-8262-17ce19675825",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "ITOP FI",
                icon: "router.png",
              },
            ],
          },
          {
            title: "Plus + Max",
            speed: 800,
            speedType: "MB",
            amount: 134.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/5be25aea-844b-4be2-9177-f2231a6ec7cb",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Essencial Pro",
        plans: [
          {
            title: "Pro",
            speed: 900,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/b6c859cf-31a8-4ec4-85bd-c680fb68e4dc",
            benefits: [
              {
                title: "Wi-Fi 6  - AX3",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
          {
            title: "Pro + Móvel",
            speed: 900,
            speedType: "MB",
            amount: 209.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/634a1bc2-172d-4523-8ac8-f3c293a99874",
            benefits: [
              {
                title: "Wi-Fi 6 - AX3",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "55 GB para pacote mobile",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Pro + Globoplay",
            speed: 900,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/b2ca2602-cf5f-4194-b075-9c21cc56878d",
            benefits: [
              {
                title: "Wi-Fi 6  - AX3",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "Globoplay | c/ anúncio",
                icon: "globoplay.png",
              },
            ],
          },
          {
            title: "Pro + Wifi",
            speed: 900,
            speedType: "MB",
            amount: 134.9,
            recurrence: "mensal",
            buyLink: "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/0996bbd2-4aa0-4d6c-a61c-54f9a6447b2e",
            benefits: [
              {
                title: "Wi-Fi 6  - AX3",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "ITOP FI",
                icon: "router.png",
              },
            ],
          },
        ],
      },
      {
        title: "Smart Top",
        plans: [
          {
            title: "Smart Call",
            speed: 300,
            speedType: "MB",
            amount: 134.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/5f143227-816c-42e7-811f-454d61a499a4",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "25 GB para pacote mobile (1)",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Smart Link",
            speed: 600,
            speedType: "MB",
            amount: 144.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/6f90f6ab-1b8c-4678-968d-f88001b62779",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "25 GB para pacote mobile (1)",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Smart Infinity",
            speed: 700,
            speedType: "MB",
            amount: 164.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/79d277e6-d7d6-4ba6-97e1-bad70c67da76",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "35 GB para pacote mobile (1)",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Smart Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 229.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/8ab8b1a4-0d84-40f6-b1a3-8c6f66f2bb7c",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
              {
                title: "50 GB para pacote mobile (1)",
                icon: "mobile.png",
              },
            ],
          },
        ],
      },
      {
        title: "Itop Play + Globoplay",
        plans: [
          {
            title: "Play Easy",
            speed: 300,
            speedType: "MB",
            amount: 119.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/0be45e96-1cea-43ce-bb61-31b695c72c74",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Now",
            speed: 600,
            speedType: "MB",
            amount: 129.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/6561b6fd-f203-4df7-9c4c-c314cb09863d",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Zone",
            speed: 700,
            speedType: "MB",
            amount: 139.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/6a6ebaba-bd9c-4ce3-bd7d-39b9c57b79ec",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
            ],
          },
          {
            title: "Exclusive Play",
            speed: 900,
            speedType: "MB",
            amount: 179.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/98cb8149-8941-4b6b-827a-fb8fe32e812c",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Itop Play + Globoplay+",
        plans: [
          {
            title: "Play Easy",
            speed: 300,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/8481ad48-9113-449c-9b97-8ee0ca7e9a39",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay+ | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Now",
            speed: 600,
            speedType: "MB",
            amount: 159.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/c5ac117a-c17d-4897-b44b-3ac4c1620f67",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay+ | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Zone",
            speed: 700,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/4106fb66-eea0-4950-9b89-f111ce409f8c",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay+ | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
            ],
          },
          {
            title: "Exclusive Play",
            speed: 900,
            speedType: "MB",
            amount: 209.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/92cbb7d2-9bf4-4b7f-afea-f7d497f74f10",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay+ | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Itop Play + Premiere",
        plans: [
          {
            title: "Play Easy",
            speed: 300,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/1e73d096-10d1-4677-9001-f9d8824a1f5c",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Premiere",
                icon: "premiere.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Now",
            speed: 600,
            speedType: "MB",
            amount: 159.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/e5f23a4c-63de-4887-abb3-3eb6daf90094",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Premiere",
                icon: "premiere.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Zone",
            speed: 700,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/1bd5c1f6-37aa-461c-a700-9617bbaba5aa",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Premiere",
                icon: "premiere.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
            ],
          },
          {
            title: "Exclusive Play",
            speed: 900,
            speedType: "MB",
            amount: 209.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/90b77ad7-46eb-4ad3-b1b8-2a87353a7ea6",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Premiere",
                icon: "premiere.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Itop Play + Telecine",
        plans: [
          {
            title: "Play Easy",
            speed: 300,
            speedType: "MB",
            amount: 114.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/d2421f21-3207-4a6e-9e15-3ae5e5b6e7f9",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Telecine",
                icon: "telecine.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Now",
            speed: 600,
            speedType: "MB",
            amount: 124.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/548daf30-8102-4416-8a27-1c2022f7c694",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Telecine",
                icon: "telecine.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Zone",
            speed: 700,
            speedType: "MB",
            amount: 134.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/5c56c8c4-08d4-402c-a75c-853549f9cbaa",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Telecine",
                icon: "telecine.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
            ],
          },
          {
            title: "Exclusive Play",
            speed: 900,
            speedType: "MB",
            amount: 174.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/2de3ac99-6c26-425d-b6bb-d9013cf8c2f6",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Telecine",
                icon: "telecine.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Família Top",
        plans: [
          {
            title: "Conexão Família",
            speed: 600,
            speedType: "MB",
            amount: 204.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/57fe59b5-2e12-4e87-b163-a449b2e645eb",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "35 GB para pacote mobile (2)",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Conexão Plus",
            speed: 700,
            speedType: "MB",
            amount: 264.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/0799128b-6ec6-42b2-8c8f-85057d8e0fc9",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "50 GB para pacote mobile (2)",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Conexão Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 304.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/267511ec-73d7-40ba-aa66-9853800008bf",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
              {
                title: "50 GB para pacote mobile (2)",
                icon: "mobile.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Pessoa jurídica",
    plans: [
      {
        title: "Enterprise",
        plans: [
          {
            title: "Enterprise Slim",
            speed: 600,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/71c05a8e-f021-439d-b664-1b47bba19ca0",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 25 GB",
                icon: "mobile.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
          {
            title: "Enterprise Premium",
            speed: 700,
            speedType: "MB",
            amount: 179.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/ddd2475b-4acb-471b-9198-867bd0b18504",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 35 GB",
                icon: "mobile.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
          {
            title: "Enterprise Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 199.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/ebc03ce1-6d59-4396-aa3f-de7e52485dec",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 35 GB",
                icon: "mobile.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
        ],
      },
      {
        title: "Corp",
        plans: [
          {
            title: "Corp Conect",
            speed: 600,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/9fab685a-a780-4e92-bc37-39e479139a7d",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 25 GB",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Corp Plus",
            speed: 700,
            speedType: "MB",
            amount: 159.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/142e740e-b672-4067-8361-7b30fa606edd",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 35 GB",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Corp Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 184.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/6c9f9577-795c-49cb-9d2f-c0aeb2564303",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 50 GB",
                icon: "mobile.png",
              },
            ],
          },
        ],
      },
      {
        title: "Business",
        plans: [
          {
            title: "Business Line",
            speed: 600,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/55f9f4f5-cde8-4add-9546-d8583152230f",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
          {
            title: "Business Master",
            speed: 700,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/150830ff-cbf6-4354-b3f9-a210505ac047",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
          {
            title: "Business Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 189.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/7ef22983-7026-4925-805d-d27f4da6796a",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default Plans;
