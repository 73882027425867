import ChangeCity from "components/Location/ChangeCity";
import { StyledBox, Video, Content, Overlay } from "./styles";

export default function Location() {
  return (
    <StyledBox
      display={"flex"}
      alignContent="center"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Video src="/top.webm" autoPlay loop muted playsInline />
      <Overlay />
      <Content>
        <ChangeCity />
      </Content>
    </StyledBox>
  );
}
