import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  z-index: 0;
`;

export const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;
