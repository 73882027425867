import { Box } from "@mui/material";
import ElectricBoltOutlinedIcon from "@mui/icons-material/ElectricBoltOutlined";
import {
  Title,
  ColoredTitle,
  SubTitle,
  StyledBox,
  PageChip,
  Video,
  Content,
} from "./styles";

export default function Initial() {
  return (
    <StyledBox
      display={"flex"}
      alignContent="center"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Video src="/top.webm" autoPlay loop muted playsInline />
      <Content>
        <Box
          display={"flex"}
          alignContent="center"
          justifyContent={"center"}
          alignItems={"center"}
          pb={1}
          pt={{ xs: 7, sm: 7, md: 2, lg: 0, xl: 0 }}
        >
          <PageChip>
            <ElectricBoltOutlinedIcon
              sx={{
                fontSize: "16px",
                marginRight: "7px",
                color: "#ff7c06",
              }}
            />
            MAIS VELOCIDADE
          </PageChip>
        </Box>
        <Box pb={1}>
          <Title>
            A internet que <ColoredTitle>você precisa!</ColoredTitle>
          </Title>
        </Box>
        <Box>
          <SubTitle>
            Confira abaixo os planos disponíveis na sua cidade ou monte o seu
            plano personalizado
          </SubTitle>
        </Box>
        <Box>
          {/* <StyledButton
            onClick={() => navigate("/monte-seu-plano")}
            variant="contained"
            size="large"
          >
            Monte seu plano
            <EastIcon
              sx={{ fontSize: "19px", marginTop: "2.5px", marginLeft: "8px" }}
            />
          </StyledButton> */}
        </Box>
      </Content>
    </StyledBox>
  );
}
