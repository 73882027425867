import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Grid,
  CardContent,
} from "@mui/material";
import { Card } from "@mui/material";
import { styled } from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

export interface MoreAppModalProps {
  modalIsOpen: boolean;
  onClose: () => void;
}

const StyledCard = styled(Card)`
  background: rgba(0, 0, 0, 0.6) !important;
  color: white;
  border: 1px solid #1a1a1a;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
`;

const PriceText = styled(Typography)`
  font-size: 20px !important;
  color: white;
  @media (max-width: 850px) {
    font-size: 16px !important;
  }
`;

export default function MoreAppModal({
  modalIsOpen,
  onClose,
}: MoreAppModalProps): JSX.Element {
  const formatAmount = (amount: number): string => {
    return amount.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  const telephony = [
    { name: "Móvel ilimitado 15GB", value: 40.0 },
    { name: "Móvel ilimitado 30GB", value: 50.0 },
    { name: "Móvel ilimitado 40GB", value: 60.0 },
    { name: "Móvel ilimitado 55GB", value: 85.0 },
    { name: "Fixo ilimitado local", value: 34.9 },
    { name: "Fixo ilimitado Brasil", value: 69.9 },
  ];

  const additionalApps = [
    { name: "Deezer", value: 9.9 },
    { name: "Globoplay com anúncios", value: 22.9 },
    { name: "Globoplay sem anúncios", value: 32.9 },
    { name: "Globoplay Premium", value: 54.9 },
    { name: "Telecine", value: 23.9 },
    { name: "Premiere", value: 49.9 },
  ];

  return (
    <Dialog
      open={modalIsOpen}
      onClose={onClose}
      disableScrollLock
      PaperProps={{
        sx: {
          minWidth: "700px",
          backgroundImage: "linear-gradient(200deg, #000000, #FF7C06, #000000)",
          borderRadius: "20px",
          padding: "1.5rem",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          "@media (max-width: 850px)": {
            minWidth: "unset",
            width: "90%",
            padding: "1rem",
          },
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={2}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8, color: "#FFFFFF" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <StyledCard sx={{ borderRadius: "15px" }}>
              <CardContent>
                <Typography
                  color="#FF7C06"
                  fontSize="30px"
                  fontWeight={800}
                  sx={{
                    "@media (max-width: 850px)": {
                      fontSize: "22px",
                    },
                  }}
                  pb={1}
                >
                  Telefonia
                </Typography>
                {telephony.map((item, index) => (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <PriceText>{item.name}</PriceText>
                    <PriceText>{formatAmount(item.value)}</PriceText>
                  </Box>
                ))}
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12}>
            <StyledCard sx={{ borderRadius: "15px" }}>
              <CardContent>
                <Typography
                  color="#FF7C06"
                  fontSize="30px"
                  fontWeight={800}
                  sx={{
                    "@media (max-width: 850px)": {
                      fontSize: "22px",
                    },
                  }}
                  pb={1}
                >
                  Aplicativos adicionais
                </Typography>
                {additionalApps.map((item, index) => (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <PriceText>{item.name}</PriceText>
                    <PriceText>{formatAmount(item.value)}</PriceText>
                  </Box>
                ))}
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
