import styled from "styled-components";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export const Title = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 51px;
  font-weight: 700;

  @media (max-width: 1200px) {
    text-align: center;
    font-size: 31px;
    font-weight: 700;
  }
`;

export const SubTitle = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 21px;
  font-weight: 400;

  @media (max-width: 1200px) {
    text-align: center;
    font-size: 19px;
    font-weight: 400;
  }
`;

export const ColoredTitle = styled.span`
  color: #ff7c06;
`;

export const StyledButton = styled(Button)`
  && {
    background-color: #2d3e91;
    text-transform: none;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 600;
    border-radius: 30px;
    width: 270px;
    height: 60px;

    &:hover {
      background-color: #ff7c06;
      color: black;
    }
  }
`;

export const PageChip = styled(Box)`
  font-weight: 600;
  width: 200px;
  height: 40px;
  border: solid 1px #ff7c06;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #fff;
`;

export const StyledBox = styled(Box)`
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: -1;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Content = styled(Box)`
  position: absolute;
  width: 100%;
  top: 350px;
  padding: 0px 15px;
  z-index: 1;

  @media (max-width: 600px) {
    top: 250px;
  }
`;
