import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import LocalStorage from "helpers/LocalStorage";
import InputCity from "./components/InputCity";
import ListCities from "./components/ListCities";
import { cities } from "services";
import { iCity } from "data/interfaces/cities";

export default function ChangeCity() {
  const [listCities, setCities] = useState<iCity[]>([]);
  const [itopCities, setItopCities] = useState<iCity[]>([]);
  const city = LocalStorage.get("city");

  const handleChange = (value: string) => {
    const filteredCities: iCity[] = itopCities.filter(
      (city) =>
        city.city.toLowerCase().includes(value.toLowerCase()) ||
        city.uf.toLowerCase().includes(value.toLowerCase())
    );
    setCities(filteredCities);
  };

  useEffect(() => {
    setCities(cities.getCities());
    setItopCities(cities.getCities());
  }, []);

  useEffect(() => {
    if (city) {
      handleChange(city.city);
    }
    // eslint-disable-next-line
  }, [itopCities]);

  return (
    <Grid
      container
      justifyContent="center"
      width={{ xs: "90%", sm: 500, md: 500, lg: 500, xl: 500 }}
    >
      <Grid item xs={11}>
        <InputCity onChange={handleChange} />
        <ListCities cities={listCities} />
      </Grid>
    </Grid>
  );
}
