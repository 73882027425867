import { useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { Title, Text, OpenCreatePlan } from "./styles";
import MoreAppModal from "components/MoreAppModal";
import TooglePerson from "./TooglePerson";
import TabsPlans from "./TabsPlans";
import Plans from "data/plans";

export default function OurPlans() {
  const [typePerson, setTypePerson] = useState<string>("Pessoa física");
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [typePersonId, setTypePersonId] = useState<number>(0);
  const [typePlan, setTypePlan] = useState<number>(0);

  const handleTypePerson = (event: any) => {
    setTypePerson(event.target.value);
    setTypePersonId(event.target.id);
    setTypePlan(0);
  };

  const handleTypePlan = (value: number) => {
    setTypePlan(value);
  };

  const handleClick = () => {
    setModalIsOpen(true);
  };

  return (
    <>
      <MoreAppModal
        modalIsOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      />
      <Stack
        sx={{
          backgroundColor: "#2E2E2E",
        }}
      >
        <Grid
          display="flex"
          container
          justifyContent={"center"}
          textAlign="center"
        >
          <Grid item xs={11.5}>
            <Box textAlign="center" pt={4}>
              <Title>Nossos planos</Title>
            </Box>
            <Box display="flex" justifyContent={"center"}>
              <TooglePerson
                onChange={handleTypePerson}
                typePerson={typePerson}
              />
            </Box>
            <Box>
              <TabsPlans
                allPlans={Plans}
                typePersonId={typePersonId}
                typePlan={typePlan}
                onChangePlan={handleTypePlan}
              />
            </Box>
            <Box pb={7} textAlign="center">
              <Box pb={2}>
                <OpenCreatePlan onClick={() => handleClick()}>
                  Telefonia & Aplicativos adicionais
                </OpenCreatePlan>
              </Box>
              <Text>
                *Fidelidade 12 meses. *Mediante a viabilidade técnica.
              </Text>
              <Text>
                Os planos são válidos até a data de vencimento da fatura.
              </Text>
              <Text>
                Para acessar o contrato{" "}
                <OpenCreatePlan
                  onClick={() => window.open("/contrato-itop.pdf", "_blank")}
                >
                  clique aqui.
                </OpenCreatePlan>
              </Text>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
